import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import SingleStudioView from "./SingleStudioView";
import { IMAGES } from "../../../src";

const SingleStudioContainer = (props) => {
  let studioId;
  if (props.studioId === undefined) {
    const studioFromUrl = props.match.params.studioName;
    studioId = studioFromUrl;
  } else {
    studioId = props.studioId;
  }

  return (
    <SingleStudioView
      studioInfo={props.studioInfo}
      key={studioId}
      studioId={studioId}
      currentStudio={props.currentStudio}
      isLoggedIn={props.isLoggedIn}
      compatibleDevices={IMAGES.compatibleDevices}
      icons={IMAGES.icons}
      restrictView={props.restrictView}
    />
  );
};
export default withRouter(SingleStudioContainer);
